import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import EditPartner from "./editModal";
import { toast } from "react-toastify";
import SaveCandidates from "./saveCandidatesModal";

const PartnersList = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [word, setWord] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [token, setToken] = useToken();

  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [partnerId, setPartnerId] = useState(null);
  const [currentRegion, setCurrentRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setCurrentRegion(null);
    setWord(word);
    ///
    axios
      .post(`/api/partners-v2`, { search: word, page: 1, year: selectedYear })
      .then((result) => {
        setData(result.data.partners);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let postData = word
      ? { search: word, page: page + 1, year: selectedYear }
      : { page: page + 1, region: currentRegion, year: selectedYear };
    setLoading(true);
    axios.post(`/api/partners-v2`, postData).then((result) => {
      setData([...data, ...result.data.partners]);
      setCount(result.data.total);
      setPages(result.data.pageCount);
      setPage(page + 1);
      setLoading(false);
    });
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const handleClickPartner = (id) => {
    setPartnerId(id);
    toggleEditModal();
  };

  if (editModal || importModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleDownload = (type = "stats") => {
    setDownloadLoading(true);
    axios
      .get(
        `/api/${
          type === "results" ? "all-" : ""
        }partner-${type}?year=${selectedYear}`,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        // console.log("result ready");
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Partners_${type}.xlsx`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      });
  };

  const getData = (region) => {
    setCurrentRegion(region);
    setLoading(true);
    axios
      .post(
        `/api/partners-v2`,
        region === "All"
          ? { year: selectedYear }
          : { region, year: selectedYear }
      )
      .then((result) => {
        setData(result.data.partners);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  useEffect(() => {
    axios.get("/api/partner-regions").then((result) => {
      // console.log(result.data);
      setRegions(["All", ...result.data.regions]);
      axios.get("/api/partner-years").then((result) => {
        setYears(result.data.years);
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    getData(currentRegion);
  }, [selectedYear]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="d-flex justify-content-between">
              <div className="">
                <h1>Partners List</h1>
              </div>
              <div className="mb-2">
                <div className="d-block mb-2 d-md-inline-block">
                  <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </div>
                  <span className="ml-2"> Total: {count}</span>
                </div>

                {/* </Collapse> */}
              </div>
            </div>
            <Separator className="mb-2" />
            <div
              className="text-zero top-right-button-container mb-2"
              style={{ display: "flex" }}
            >
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    color="primary"
                    // className="btn-xs"
                    outline
                  >
                    Actions
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={toggleImportModal}>
                      Import Candidates
                    </DropdownItem>
                    <DropdownItem
                      disabled={downloadLoading}
                      onClick={() => handleDownload("stats")}
                    >
                      {downloadLoading ? "Loading..." : "Download Stats"}
                    </DropdownItem>
                    <DropdownItem
                      disabled={downloadLoading}
                      onClick={() => handleDownload("results")}
                    >
                      {downloadLoading ? "Loading..." : "Download Results"}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <Button
                color="primary"
                className="top-right-button mx-2"
                onClick={toggleAddModal}
              >
                Add New
              </Button>
              {/* <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={() => navigate("/app/ieltspartners")}
                >
                  Partner Organizations
                </Button> */}
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    color="primary"
                    // className="btn-xs"
                    outline
                  >
                    {selectedYear}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {years.map((year) => (
                      <DropdownItem
                        key={year}
                        onClick={() => setSelectedYear(year)}
                      >
                        {year}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Colxx>
          {regions.map((region) => (
            <Button
              key={region}
              outline={currentRegion !== region}
              color="primary"
              className="m-1"
              onClick={() => getData(region)}
            >
              {region}
            </Button>
          ))}
        </Row>
        <br />
        <Row>
          <div className="loading" hidden={!loading} />
          {count === null && (
            <p>Please search for the data from the input above. </p>
          )}
          {!loading && count !== null && data.length === 0 ? (
            <p> List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <span
                        onClick={() => navigate(user._id)}
                        className="w-40 w-sm-100 clickable"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {user.organization}
                        </p>
                      </span>
                      <span
                        onClick={() => handleClickPartner(user._id)}
                        className="w-15 w-sm-100 clickable"
                      >
                        <p className="mb-1 text-small">{user.telegramId}</p>
                      </span>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        <span className="green">
                          <strong>{user.paid}</strong>
                        </span>{" "}
                        / {user.totalCandidates} ({user.successRate}
                        %)
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Staff: {user.orgStaff}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          <Colxx xxs="12" className="mb-3">
            {!loading && data.length !== 0 && pages !== page && (
              <div className="col-12">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={handleLoadMore}
                  disabled={loading}
                >
                  More...
                </Button>
              </div>
            )}
          </Colxx>
        </Row>

        {importModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <SaveCandidates toggle={toggleImportModal} />
            </div>
          </div>
        )}
        {editModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditPartner toggle={toggleEditModal} partnerId={partnerId} />
            </div>
          </div>
        )}
        {addModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditPartner toggle={toggleAddModal} partnerId={null} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default PartnersList;
