export const currencyFormatter = (value = "", symbol) => {
  if (value) {
    // console.log("value", value);
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
      symbol === "" ? symbol : ` ${symbol}`
    }`;
  } else {
    return null;
  }
};

export const formatDate = (dateString) => {
  const parts = dateString.split("/");
  if (parts.length !== 3) {
    return null; // Invalid date format
  }

  // Add leading zeros if needed
  const month = parts[0].padStart(2, "0");
  const day = parts[1].padStart(2, "0");
  const year = parts[2];

  return `${day}/${month}/${year}`;
};
