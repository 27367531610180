import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Badge,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

const ResultModal = (props) => {
  const { toggle, result, candidate } = props;

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <img className="mb-3" src="/assets/logos/idp.svg" alt="logo" />
            {/* <h1>IELTS Test Result</h1> */}
            <span
              className="clickable float-right text-md"
              color="empty"
              onClick={toggle}
            >
              <i className="simple-icon-close" />
            </span>
          </div>
        </Colxx>
      </Row>

      <Row className="text-left">
        <Colxx xxs="12" className="mb-2">
          <Card className="mb-4">
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" className="mb-3">
                    <h2 className="text-red-bur">Candidate Number</h2>
                    <p className="text-md">{result.CandidateNumber}</p>
                  </Col>
                  <Col md="6" className="mb-3">
                    <h2 className="text-red-bur">Candidate Name</h2>
                    <p className="text-md">
                      {result.FirstName || candidate?.Candidate}
                    </p>
                  </Col>
                  <Col md="6" className="mb-3">
                    <h2 className="text-red-bur">TRF Number</h2>
                    <p className="text-md">{result.TRFNumber}</p>
                  </Col>
                  <Col md="6" className="mb-3">
                    <h2 className="text-red-bur">Centre Number</h2>
                    <p className="text-md">{result.CentreID} </p>
                  </Col>
                  <Col md="6" className="mb-3">
                    <h2 className="text-red-bur">Test Date</h2>
                    <p className="text-md">{result.TestDate}</p>
                  </Col>

                  <Col md="12" className="">
                    <Card
                      className="rounded-sm"
                      style={{ background: "#333541" }}
                    >
                      <CardBody>
                        <h2 className="text-white font-weight-semibold">
                          About this result
                        </h2>
                        <p className="text-white">
                          Your results available online are not official. They
                          are provisional only until you receive your official
                          Test Report Form (TRF).
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12">
          <div className="text-left align-self-center justify-content-between mx-auto dr-flex">
            <Card className=" rounded-lg mb-1">
              <CardBody className="mx-3 padding-md">
                <h2 className="font-weight-semibold">Speaking</h2>
                <p className="text-large font-weight-xbold text-red">
                  {result.SScore}
                </p>
              </CardBody>
            </Card>
            <Card className=" rounded-lg mb-1">
              <CardBody className="mx-3 padding-md">
                <h2 className="font-weight-semibold">Listening</h2>
                <p className="text-large font-weight-xbold text-red">
                  {result.LScore}
                </p>
              </CardBody>
            </Card>
            <Card className=" rounded-lg mb-1">
              <CardBody className="mx-3 padding-md">
                <h2 className="font-weight-semibold">Reading</h2>
                <p className="text-large font-weight-xbold text-red">
                  {result.RScore}
                </p>
              </CardBody>
            </Card>
            <Card className=" rounded-lg mb-1">
              <CardBody className="mx-3 padding-md">
                <h2 className="font-weight-semibold">Writing</h2>
                <p className="text-large font-weight-xbold text-red">
                  {result.WScore}
                </p>
              </CardBody>
            </Card>
            <Card className=" rounded-lg red-bg mb-1">
              <CardBody className="mx-3 padding-md">
                <h2 className="text-white font-weight-semibold">Overall</h2>
                <p className="text-large font-weight-xbold text-white">
                  {result.BandScore}
                </p>
              </CardBody>
            </Card>
          </div>
        </Colxx>
      </Row>
    </div>
  );
};

export default ResultModal;
