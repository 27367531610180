import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axiosClient";
import { toast } from "react-toastify";

import { Colxx, Separator } from "components/common/CustomBootstrap";
import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import ConfirmModal from "./confirmModal";
import moment from "moment";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const SpeakingDatesList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [token, setToken] = useToken();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [examDateStart, setExamDateStart] = useState(null);
  const [examDateEnd, setExamDateEnd] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let isMounted = true;
    let region = searchParams.get("region");
    let url;
    // eslint-disable-next-line default-case
    switch (region) {
      case "Tashkent":
        url = "/api/speaking/dates";
        break;
      case "cd":
        url = "/api/speaking/dates?search=cd";
        break;
      case "regions":
        url = "/api/speaking/dates?region=true";
        break;
      case "regionscd":
        url = "/api/speaking/dates?region=true&search=cd";
        break;
    }
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          setData(result.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/speaking/dates?search=${word}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };

  const handleSearchRegion = (word) => {
    ///
    axios
      .get(`/api/speaking/dates?region=true`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };

  const handleSearchCd = (word) => {
    ///
    axios
      .get(`/api/speaking/dates?search=cd`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };

  const handleDownloadMulti = async () => {
    setDownloadLoading(true);
    // eslint-disable-next-line default-case

    axios
      .post(
        `/api/speaking-download-excel-multi`,
        {
          mainTestStart: moment(examDateStart).format("DD/MM/YYYY"),
          mainTestEnd: moment(examDateEnd).format("DD/MM/YYYY"),
          venues: [...data].map((obj) => obj.venue),
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Speaking-candidates.xlsx`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setDownloadLoading(false);
      });
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDelete = () => {
    axios
      .delete(`/api/speaking/date/${selectedId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          toast.success("Successfully deleted");
          navigate(0);
        }
      })
      .catch((err) => {
        toast.error(
          "There are some slots in this Session, please delete them first."
        );
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="ml-2 d-block d-flex justify-content-between">
              <div className="mb-2">
                <h1>Speaking Sessions List</h1>
                <div className="text-zero top-right-button-container"></div>
              </div>
              <div className="mb-2">
                <div className="d-block d-md-inline-block pt-1">
                  <div className="search-sm d-inline-block mb-1 ">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Separator className="mb-1" />
            <div className="ml-2 d-block d-flex justify-content-between">
              <div>
                Start:{" "}
                <DatePicker
                  className={"custom-datepicker"}
                  calendarIcon={<i className="simple-icon-calendar" />}
                  clearIcon={<i className="iconsminds-close" />}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  format="dd/MM/y"
                  value={examDateStart ? examDateStart : null}
                  showLeadingZeros={true}
                  onChange={(v) => {
                    setExamDateStart(v);
                  }}
                />{" "}
                End:{" "}
                <DatePicker
                  className={"custom-datepicker"}
                  calendarIcon={<i className="simple-icon-calendar" />}
                  clearIcon={<i className="iconsminds-close" />}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  format="dd/MM/y"
                  value={examDateEnd ? examDateEnd : null}
                  showLeadingZeros={true}
                  onChange={(v) => {
                    setExamDateEnd(v);
                  }}
                />{" "}
                <Button
                  color="light"
                  className="top-right-button mb-2 mr-2"
                  disabled={downloadLoading}
                  onClick={() => handleDownloadMulti()}
                >
                  Download Candidates
                </Button>
              </div>
              <div></div>
              <div>
                <Button
                  color="light"
                  className="top-right-button mb-2 mr-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  className="top-right-button mb-2"
                  onClick={() => navigate("add")}
                >
                  Add new
                </Button>
              </div>
            </div>
            {/* <Button
                  color="primary"
                  className="top-right-button"
                  onClick={() => navigate("add")}
                >
                  Add new
                </Button> */}
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((date) => (
              <Colxx xxs="12" key={date._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${date._id}`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {date.name}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {date.mainTestDate}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Slots: {date.slots.length}
                      </p>
                      <p className="mb-1 text-muted text-small w-5 w-sm-100">
                        <i
                          className="simple-icon-trash ml-2 clickable"
                          onClick={() => {
                            setSelectedId(date._id);
                            toggleDeleteModal();
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>

        {deleteModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <ConfirmModal toggle={toggleDeleteModal} action={handleDelete} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default SpeakingDatesList;
