import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import axios from "axiosClient";
import { useUser } from "auth/useUser";
import IconCard from "components/cards/IconCard";
import GradientWithRadialProgressCard from "components/cards/GradientWithRadialProgressCard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ResultModal from "./resultModal";
import { formatDate } from "utils/formatter";

const CandidatesList = ({ partner, partnerId }) => {
  const [data, setData] = useState([]);
  const [cardValues, setCardValues] = useState({
    paid: null,
    awaiting: null,
    cancelled: null,
  });
  const [candidate, setCandidate] = useState({});
  const [count, setCount] = useState(null);
  const [word, setWord] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [availableForBonus, setAvailaleForBonus] = useState(false);
  const [bonuses, setBonuses] = useState(0);
  const [bonusesAct, setBonusesAct] = useState(0);
  const [paidRemainder, setPaidRemainder] = useState(0);
  const [sendModal, setSendModal] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [result, setResult] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear() + ""
  );

  const [token, setToken] = useToken();
  const navigate = useNavigate();

  const cardsData = [
    { title: "Paid", icon: "iconsminds-yes", value: cardValues.paid },
    {
      title: "Awaiting Payment",
      icon: "iconsminds-synchronize-2",
      value: cardValues.awaiting,
    },
    {
      title: "Cancelled",
      icon: "iconsminds-close",
      value: cardValues.cancelled,
    },
  ];

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setWord(word);
    ///
    axios
      .post(`/api/partner-candidates`, { search: word, page: 1, partnerId })
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let postData = { partnerId, page: page + 1 };
    if (word) {
      postData.search = word;
    }
    axios.post(`/api/partner-candidates`, postData).then((result) => {
      setData([...data, ...result.data.candidates]);
      setCount(result.data.total);
      setPages(result.data.pageCount);
      setPage(page + 1);
      setLoading(false);
    });
  };

  const handleCopyClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Successfully copied");
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  if (sendModal || resultModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const toggleResultModal = () => {
    setResultModal(!resultModal);
  };

  const handleGetResult = (user) => {
    setResultLoading(true);
    axios
      .get(
        `/api/get-result?id=${user.IDNo}&date=${formatDate(user.SessionDate)}`
      )
      .then((result) => {
        if (!result.data.success) {
          return toast.error(result.data.message);
        }
        setCandidate(user);
        setResult(result.data.data);
        setResultLoading(false);
        toggleResultModal();
      })
      .catch((err) => {
        setResultLoading(false);
        toast.error(err.response?.data.message || "Something went wrong");
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post(`/api/partner-candidates`, { partnerId, year: selectedYear })
      .then(({ data }) => {
        setData(data.candidates);
        setCardValues(data.cardValues);
        setYears(data.cardValues.years);
        // console.log(result.data.cardValues.paid);
        // console.log(partner.usedBonuses);
        let bonusesApiAc =
          Math.floor(data.cardValues.paidForBonuses / data.partner.threshold) -
          data.cardValues.usedBonuses;
        let bonusesApiPre =
          Math.floor(
            (data.cardValues.paidForBonuses +
              (partner?.additionalCandidates
                ? partner?.additionalCandidates[selectedYear] || 0
                : 0)) /
              data.partner.threshold
          ) - data.cardValues.usedBonuses;
        let bonusesApi =
          bonusesApiPre > 0
            ? parseInt(selectedYear) === parseInt(new Date().getFullYear())
              ? bonusesApiPre || 0
              : 0
            : bonusesApiPre;
        setBonusesAct(bonusesApiAc);
        setBonuses(bonusesApi || 0);

        setPaidRemainder(
          (data.cardValues.paid +
            (partner?.additionalCandidates
              ? partner?.additionalCandidates[selectedYear] || 0
              : 0)) %
            data.partner.threshold
        );

        if (bonusesApi > 0) {
          setAvailaleForBonus(true);
        }
        setCount(data.total);
        setPages(data.pageCount);
        setPage(data.page);
        setLoading(false);
      });
  }, [selectedYear]);

  return (
    <>
      <Row className="icon-cards-row mb-4">
        <Col lg="12">
          <div className="text-zero d-flex justify-content-between mb-2">
            <div>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  color="primary"
                  // className="btn-xs"
                  outline
                >
                  {selectedYear}
                </DropdownToggle>
                <DropdownMenu start>
                  {years.map((year) => (
                    <DropdownItem
                      key={year}
                      onClick={() => setSelectedYear(year)}
                    >
                      {year}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div></div>
          </div>
          <Row>
            {cardsData.map((cardsData, index) => (
              <Col key={index} md="2" xs="4">
                <IconCard {...cardsData} className="m-2" />
              </Col>
            ))}
            <Col md="6" xs="12" className="mt-2 mb-2">
              {!loading && (
                <GradientWithRadialProgressCard
                  icon="iconsminds-network"
                  title={`Available Free Seats: ${
                    bonuses || 0
                  } (Ac: ${bonusesAct})`}
                  detail={`${
                    partner.threshold - paidRemainder
                  } remaining for the next free seat`}
                  detail2={
                    selectedYear == new Date().getFullYear()
                      ? `${cardValues.usedBonuses} used bonuses`
                      : ""
                  }
                  percent={(paidRemainder * 100) / partner.threshold}
                  progressText={`${paidRemainder}/${partner.threshold}`}
                />
              )}
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <p style={{ textAlign: "center" }}>
                <strong>Your Booking Link:</strong> {partner?.bookingUrl}{" "}
                <span
                  className="clickable"
                  onClick={() => handleCopyClipboard(partner?.bookingUrl)}
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="iconsminds-duplicate-layer"
                  />
                </span>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Colxx xxs="12">
          <div className="d-flex justify-content-between">
            <div>
              <h1>Candidates List</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              ></div>
            </div>
            <div>
              <div className="d-block mb-2 d-md-inline-block">
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
                <span className="ml-2"> Total: {count}</span>
              </div>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <div className="loading" hidden={!loading} />
        {count === null && (
          <p>Please search for the data from the input above. </p>
        )}
        {!loading && count !== null && data.length === 0 ? (
          <p> List is empty</p>
        ) : (
          data.map((user) => (
            <Colxx xxs="12" key={user._id} className="mb-3">
              <Card
                className={`d-flex flex-row ${
                  user.requestedFreeSeat ? "processing-candidate" : ""
                }`}
              >
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                    <span className="w-30 w-sm-100 clickable">
                      <p className="list-item-heading mb-1 truncate">
                        {user.Candidate}
                      </p>
                    </span>
                    <span className="w-15 w-sm-50 clickable">
                      <p className="mb-1 text-small">{user.IDNo}</p>
                    </span>
                    <p className="mb-1 text-muted text-small w-15 w-sm-50">
                      {user.Status}
                    </p>
                    <p className="mb-1 text-muted text-small w-15 w-sm-40">
                      Exam: {user.SessionDate}
                    </p>
                    <p className="mb-1 text-muted text-small w-15 w-sm-40">
                      {user.Module}
                    </p>
                    <p className={`w-10 w-sm-100 clickable list-item-heading`}>
                      {user.result && (
                        <Button
                          size="xs"
                          outline
                          onClick={() => handleGetResult(user)}
                          disabled={resultLoading}
                        >
                          <i className={"iconsminds-cursor-click"} /> Result
                        </Button>
                      )}
                    </p>
                  </div>
                </div>
              </Card>
            </Colxx>
          ))
        )}
        <Colxx xxs="12" className="mb-3">
          {!loading && data.length !== 0 && pages !== page && (
            <div className="col-12">
              <Button
                color="primary"
                className="top-right-button"
                onClick={handleLoadMore}
              >
                More...
              </Button>
            </div>
          )}
        </Colxx>
      </Row>
      {resultModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content max-modal">
            <ResultModal
              toggle={toggleResultModal}
              result={result}
              candidate={candidate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CandidatesList;
