import { getToken } from "auth/useToken";
import axios from "axios";
// const setUrl = "http://localhost:6005";
const setUrl = "https://api-uz.edu-action.uk/";

const axiosClient = axios.create({
  baseURL: setUrl,
});

// Add request interceptor
axiosClient.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
);
export default axiosClient;
